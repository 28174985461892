
function E(id) {
    if(typeof jQuery != 'undefined' && id instanceof jQuery) {
        return id[0];
    } else
    if(typeof HTMLElement != 'undefined') {
        if(id instanceof HTMLElement) {
            return id;
        }
    } else {
        if(typeof id != 'string') {
            return id;
        }
    }

    if(id.indexOf(' ')!=-1 || id.indexOf('#')!=-1 || id.indexOf('.')!=-1) {
        return $(id)[0];
    }

    var e = document.getElementById(id);
    if(e) return e;
    return null;
}

function setCookie(name, value, expire, path) {
	if(expire) {
		var expireDate = new Date();
		expireDate.setDate(expire + expireDate.getDate());
		
		document.cookie = name + "=" + value + ";" + " expires=" + expireDate.toGMTString() + ";" + " path=" + path + ";";
	} else {
		document.cookie = name + "=" + value + ";" + " path=/;";
	}
}

var CMS = new function() {

	var CMS = this;
    var $ = jQuery;
	
	CMS.runScripts = function(html) {
		if(!html) return;

        CMS.registerLoadedScripts();

		html = html.replace(/\/\*\<\!\[CDATA\[\*\//g, '');
		html = html.replace(/\/\*\]\]\>\*\//g, '');

        var mat;
        var mat2;

        CMS.loadCss(html);

		var scriptfiles = [];
		var scripts = [];

        var re = /\<script([^>]*)\>([^\v]*?)\<\/script/gim;
        var re2 = /src="([^"]*)"/i;
        while((mat = re.exec(html)) != null) {
            if(mat[1]) {
                if(mat2 = re2.exec(mat[1])) {
                    scriptfiles.push(mat2[1]);
                }
            }
            if(mat[2]) {
                scripts.push(mat[2]);
            }
        }

		if(scriptfiles.length) {
			$.requireScript(scriptfiles, function() {
				if(scripts.length)
				for(i in scripts) {
					jQuery.globalEval(scripts[i]);
				}
			}, window);
		} else {
			if(scripts.length)
			for(i in scripts) {
				jQuery.globalEval(scripts[i]);
			}
		}
	}

	CMS.doPost = function(url, postdata) {
		var form = document.createElement('form');
		form.setAttribute('method', 'post');
		form.setAttribute('action', url);
		
		var input;
		
		for(key in postdata) {
			input = document.createElement('input');
			input.setAttribute('type', 'hidden');
			input.setAttribute('name', key);
			input.setAttribute('value', postdata[key]);
			form.appendChild(input);
		}
		
		form = document.body.appendChild(form);
		
		form.submit();
	}

    var popupStack = [];

	CMS.showPopup = function(classname, popupcontent) {
        var widget = {
            close: function() {
                if($.detectCSSFeature('transition')) {
                    var w = popup.find('.myPopup-window');

                    w.on('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function() {
                        close();
                    })

                    w.addClass('animation-close');
                } else {
                    close();
                }

                function close() {
                    popupStack.pop();
                    popup.remove();

                    if(popupStack.length) {
                        popupStack[popupStack.length-1].removeClass('disabled').find('.myPopup-content').attr('id', 'popupcontent');
                    } else {
                        document.body.style.overflow = '';
                    }
                }
            }
        }

        if(popupStack.length) {
            popupStack[popupStack.length-1].addClass('disabled').find('.myPopup-content').attr('id', '');
        } else {
            document.body.style.overflow = 'hidden';
        }

        var popup = $('<div class="myPopup" />').data('showPopup', widget);
        if(classname) popup.addClass(classname);

        var popupHider = $('<div class="myPopup-hider" />').appendTo(popup);
        var popupWrapper = $('<div class="myPopup-wrapper" />').appendTo(popup);
        var popupWindow = $('<div class="myPopup-window" />').appendTo(popupWrapper);
        var popupClose = $('<div class="myPopup-close" />').appendTo(popupWindow);
        var popupContent = $('<div class="myPopup-content" id="popupcontent" />').appendTo(popupWindow);

        widget.$ = popupContent;

        popupContent.css('maxHeight', Math.round($(window).height()*0.8));
        $(window).resize(function() {
            popupContent.css('maxHeight', Math.round($(window).height()*0.8));
        });

        if(popupcontent) {
            popupContent.empty()[0].innerHTML = popupcontent;
            popupContent.trigger('init');
        } else {
            popupContent.html('<div class="myPopup-preloader"></div>');
        }

        var closefn = function(event) {
            if(event.target==this) {
                widget.close();
            }
        };

        popupClose.click(closefn);
        popupHider.click(closefn);
        popupWrapper.click(closefn);

        $(popupContent).on('beforeUpdate', function() {
            $(this).css({height:$(this).height()}).stop().animate({opacity:0}, 500);
        });
        $(popupContent).on('afterUpdate', function() {
            if($(this).children().length>1) {
                $(this).wrapInner('<div />');
            }
            $(this).stop().animate({opacity:1, height:$(this).children().first().height()}, 800, function(){$(this).css('height', 'auto')});
        });

        $(document.body).append(popup);
        popupStack.push(popup);

        return widget;
	}

	CMS.hidePopup = function(popupid) {
        if(popupStack.length) {
            var current = popupStack.pop();
            current.data('showPopup').close();
        }
	}

    CMS.showHider = function(time, classname, nopreloader) {
        var div_hider = document.createElement('div');
        div_hider.id = 'hider';
        div_hider.style.position = 'fixed';
        div_hider.style.width = '100%';
        div_hider.style.height = '100%';
        div_hider.style.left = '0';
        div_hider.style.top = '0';
        div_hider.style.zIndex = '9999';
        document.body.appendChild(div_hider);
        $(div_hider).disableSelection();

        if(time) {
            setTimeout(function() {
                CMS.hideHider(div_hider);
            }, time);
        }

        var div_overlay = document.createElement('div');
        div_overlay.style.position = 'absolute';
        div_overlay.style.width = '100%';
        div_overlay.style.height = '100%';
        div_overlay.style.left = '0';
        div_overlay.style.top = '0';
        div_hider.appendChild(div_overlay);
        $(div_overlay).disableSelection();

        var div = document.createElement('div');
        div.className = classname;
        div.style.position = 'absolute';
        div.style.width = '100%';
        div.style.height = '100%';
        div.style.left = '0';
        div.style.top = '0';
        div.style.textAlign = 'center';
        div.style.verticalAlign = 'middle';
        div.innerHTML = '<table style="width: 100%;height: 100%;border-collapse: collapse;padding: 0;margin: 0"><tr><td style="text-align: center;vertical-align: middle">'+(nopreloader ? '' : '<img src="/img/preloader2.gif" border="0">')+'</td></tr></table>';
        div_hider.appendChild(div);
        $(div).disableSelection();

        return div_hider;
    }

    CMS.hideHider = function(hider) {
        if(hider) {
            $(hider).remove();
        } else {
            $('#hider').remove();
        }
    }

    var _registerLoadedScripts = false;
	CMS.registerLoadedScripts = function() {
        if(_registerLoadedScripts) return;
        else _registerLoadedScripts = true;

		$('script').each(function(index, element){
			var src = $(element).attr('src');
			if(src) {
				$.requireScript.registerLoaded(src);
			}
		});
	}

    CMS.loadCss = function(html) {
        if(typeof html == 'string') {
            $('link[type="text/css"]', '<div>'+html+'</div>').each(function() {
                var css = $(this);
                if(!$(document).find('link[href="'+css.attr('href')+'"]').length) {
                    var link = document.createElement('link');
                    link.href = css.attr('href');
                    if(css.attr('media')) link.media = css.attr('media');
                    link.rel = 'stylesheet';
                    link.type = 'text/css';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
            });
        } else {
            for(var i in html) {
                var href = html[i];
                if(!$(document).find('link[href="'+href+'"]').length) {
                    var link = document.createElement('link');
                    link.href = href;
                    link.rel = 'stylesheet';
                    link.type = 'text/css';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
            }
        }
    }

    CMS.doAjax = function(url, target, post, onError, onSuccess, source, hiderClass, method, history) {
        if(typeof url == 'object') {
            var hash = url;
            url = hash['url'];
            target = hash['target'];
            post = hash['post'];
            onError = hash['onError'];
            onSuccess = hash['onSuccess'];
            source = hash['source'];
            hiderClass = hash['hiderClass'];
        }

        var i, j;

        if(!url) url = location.href;
        if(!target && target!==false) target = 'content';
        if(!source) source = target;
        if(!hiderClass && hiderClass!==false) hiderClass = 'hider';

        if(source && typeof source == 'string' && source.substr(0, 1)!='#' && source.substr(0, 1)!='.') {
            source = '#'+source;
        }

        if(target && typeof target == 'string' && target.substr(0, 1)!='#' && target.substr(0, 1)!='.') {
            target = '#'+target;
        }

        var hiderid = 0;
        if(hiderClass) hiderid = CMS.showHider(null, hiderClass);

        var type
        if(method) {
            type = method;
        } else {
            type = 'get';
            if(post) type = 'post';
        }

        $.ajax({
            'url':url,
            'data':post,
            'dataType':'html',
            'cache':false,
            'type':type,
            'success':function(html, textStatus, jqXHR) {
                if(history && window.history) {
                    window.history.replaceState({url:url, post:post}, '', url);
                }

                if(html.length>1) {
                    if(parseAjaxRes(html)) return true;

                    CMS.registerLoadedScripts();

                    var jHtml = $('<div>'+html+'</div>');

                    if(source===false) {
                        var first = jHtml.find('div[id]:first');
                        if(first.length) {
                            source = '#'+first.attr('id');
                            target = '#'+first.attr('id');
                        }
                    }

                    if(target!==false) {
                        if(!$.isArray(target)) {
                            target = [target];
                        }

                        if(!$.isArray(source)) {
                            source = [source];
                        }

                        var targetElements = [];
                        var oneTarget;
                        for(i in target) {
                            oneTarget = target[i];
                            if(typeof oneTarget == 'string') {
                                oneTarget = $(oneTarget);
                            }
                            targetElements.push(oneTarget);
                        }

                        var sourceElements = [];
                        var oneSource;
                        for(i in source) {
                            oneSource = source[i];
                            if(typeof oneSource == 'string') {
                                oneSource = jHtml.find(oneSource);
                                if(oneSource.length==0 && source.length==1) {
                                    oneSource = jHtml;
                                }
                            }
                            sourceElements.push(oneSource);
                        }

                        for(i in sourceElements) {
                            oneSource = sourceElements[i];
                            oneTarget = targetElements[i];

                            if(oneSource.length==0) {
                                oneTarget.remove();
                                continue;
                            }

                            if(oneSource.length>1) {
                                var lastInserted = null;
                                oneTarget.addClass('j-clean');

                                oneSource.each(function() {
                                    var el = $(this);
                                    var id = el.attr('id');
                                    var state = el.data('state');
                                    if(id) {
                                        var t = oneTarget.filter('#'+id).removeClass('j-clean');
                                        if(t.length) {
                                            if(!state || state!=t.data('state')) {
                                                t.trigger('beforeUpdate');
                                                t.empty()[0].innerHTML = el.html();
                                                t.trigger('afterUpdate');
                                                t.trigger('init');
                                            }
                                            lastInserted = t;
                                        } else {
                                            oneTarget.trigger('beforeInsert', el);
                                            if(lastInserted) {
                                                el.insertAfter(lastInserted);
                                                lastInserted = el;
                                            } else {
                                                oneTarget.first().parent().prepend(el);
                                                lastInserted = el;
                                            }
                                            oneTarget.trigger('afterInsert', el);
                                            oneTarget.trigger('init');
                                        }
                                    } else {
                                        oneTarget.trigger('beforeInsert', el);
                                        if(lastInserted) {
                                            el.insertAfter(lastInserted);
                                            lastInserted = el;
                                        } else {
                                            oneTarget.first().parent().prepend(el);
                                            lastInserted = el;
                                        }
                                        oneTarget.trigger('afterInsert', el);
                                        oneTarget.trigger('init');
                                    }
                                });

                                oneTarget.filter('.j-clean').remove();
                            } else {
                                if(oneTarget.length) {
                                    oneTarget.trigger('beforeInsert', oneSource);
                                    oneTarget.empty()[0].innerHTML = oneSource.html();
                                    oneTarget.trigger('afterInsert', oneSource);
                                    oneTarget.trigger('init');
                                }
                            }
                        }
                    }

                    CMS.runScripts(html);
                }
                if(hiderid) CMS.hideHider(hiderid);
                if(onSuccess) {
                    if(typeof onSuccess == 'string') {
                        onSuccess();
                    } else {
                        onSuccess.call();
                    }
                }
            },
            'error':function(jqXHR, textStatus, errorThrown) {
                if(onError) {
                    if(typeof onError == 'string') {
                        onError();
                    } else {
                        onError.call();
                    }
                } else
                if(jqXHR && jqXHR.responseText) {
                    alert(jqXHR.responseText);
                } else {
                    //alert('Error');
                }
            }
        });
    }
	
	CMS.ajaxPopup = function(url, source, className, post, onClose, onError) {
		if(typeof url == 'object') {
			var hash = url;
			url = hash['url'];
            source = hash['source'];
            className = hash['className'];
			post = hash['post'];
            onClose = hash['onClose'];
            onError = hash['onError'];
		}
		
		if(!source) source = 'content';
		
		var popup = CMS.showPopup(className, null, onClose);
		CMS.doAjax(url, popup.$, post, onError, null, source, false);
	}

    CMS.ajaxSendForm = function(form, target, onError, source, onSuccess, hiderClass) {
        if(!form) return true;
        $form = $(E(form));
        if(!$form) return true;

        var ok = true;
        $form.find('*[required]').each(function() {
            var el = $(this);
            if(!el.val()) {
                if(typeof LANG != 'undefined' && LANG['required']) {
                    alert(LANG['required']);
                } else {
                    alert('Заполните обязательные поля');
                }

                ok = false;
            }
        });
        if(!ok) return false;

        var post = $form.serializeArray();
        CMS.doAjax($form.attr('action'), target, post, onError, onSuccess, source, hiderClass, $form.attr('method'));

        return false;
    }
	
	CMS.ajaxSendPopupForm = function(form, source, className, onClose, onError) {
		if(!form) return true;
        $form = $(E(form));
		if(!$form) return true;

        var ok = true;
        $form.find('*[required]').each(function() {
            var el = $(this);
            if(!el.val()) {
                if(typeof LANG != 'undefined' && LANG['required']) {
                    alert(LANG['required']);
                } else {
                    alert('Заполните обязательные поля');
                }

                ok = false;
            }
        });
        if(!ok) return false;
		
		var post = $form.serializeArray();
		
		if(!source) source = 'content';
		
		CMS.showPopup(className, null, onClose);
		CMS.doAjax($form.attr('action'), 'popupcontent', post, onError, null, source, false);
		
		return false;
	}

	function getJson(str) {
		if(str && str.indexOf('{')==0) {
            return JSON.parse(str);
		}
		return false;
	}

	function parseAjaxRes(html) {
		var json = getJson(html);
		
		if(json) {
			if(json['redirect']) {
				if(json['postdata']) {
					CMS.doPost(json['redirect'], json['postdata']);
				} else {
					location.replace(json['redirect']);
				}
				return true;
			} else
            if(json['reload']) {
                location.reload();
                return true;
            } else
			if(json['error']) {
				alert(json['error']);
				return true;
			}
		}
	}

    CMS.showPhoto = function(x, y, url) {
        if(window.E5editor && E5editor().id) return;

        var popupid = 'popupfoto';

        if (E(popupid)) document.body.removeChild(E(popupid));

        document.body.style.overflow = 'hidden';

        var div_popup = document.createElement('div');
        div_popup.id = popupid;
        div_popup.style.position = 'fixed';
        div_popup.style.width = '100%';
        div_popup.style.height = '100%';
        div_popup.style.left = '0';
        div_popup.style.top = '0';
        div_popup.style.zIndex = '1000';
        document.body.appendChild(div_popup);

        var div_overlay = document.createElement('div');
        div_overlay.className = 'overlay';
        div_overlay.style.position = 'fixed';
        div_overlay.style.width = '100%';
        div_overlay.style.height = '100%';
        div_overlay.style.left = '0';
        div_overlay.style.top = '0';
        div_overlay.style.zIndex = '1000';
        div_popup.appendChild(div_overlay);

        var div = document.createElement('div');
        div.id = 'close';
        div.style.position = 'fixed';
        div.style.width = '100%';
        div.style.height = '100%';
        div.style.left = '0';
        div.style.top = '0';
        div.style.zIndex = '1000';
        div.style.textAlign = 'center';
        div.style.verticalAlign = 'middle';
        div.innerHTML = '<table style="width: 100%;height: 100%;border-collapse: collapse"><tr><td id="showfotocontent" style="vertical-align: middle;text-align: center"><img src="/img/preloader2.gif"></td></tr></table>';
        div = div_popup.appendChild(div);

        var img = document.createElement('img');
        img.id = 'close';
        img.style.border = '5px solid #FFF';
        img.onload = function() {
            var height = $(window).height()-100;
            if(img.height && height>img.height) {
                height = img.height;
            }
            img.style.height = height+'px';

            E('showfotocontent').innerHTML = '';
            E('showfotocontent').appendChild(img);

            div.onclick = function(e) {
                if(!document.all) {
                    var target = e.target;
                } else {
                    var target = event.srcElement;
                }

                document.body.style.overflow = '';
                document.body.removeChild(E(popupid));
            };
        }
        img.src = url;
    }

	CMS.Dialog = new function () {
        var dialog = this;

        this.create = function (dialogurl, startwidth, startheight, onload, onclose) {
            var root = getRootWindow();
            if (root != window) return root.CMS.Dialog.create(dialogurl, startwidth, startheight, onload, onclose);
            var document = root.document;

            if (!$(document).find('link[href="/css/dialogwindow.css"]').length) {
                var link = document.createElement('link');
                link.href = '/css/dialogwindow.css';
                link.rel = 'stylesheet';
                link.type = 'text/css';
                document.getElementsByTagName('head')[0].appendChild(link);
            }

            $.requireScript('/js/dialogwindow.js', function () {
                createWindowDialog(dialogurl, startwidth, startheight, onload, onclose);
            }, this);

            return dialog;
        }

        this.close = function () {
            this.getCurrentDialog().closeWindow();
        }

        this.getRootWindow = function () {
            return getRootWindow();
        }

        this.onClose = function () {
            var root = getRootWindow();
            if (root != window) return root.CMS.Dialog.onClose();

            dialogcount--;
            dialogs.pop();

            if(dialogs.length<1) {
                document.body.style.overflow = '';
            }
        }

        var dialogcount = 0;
        var dialogs = [];

        this.getCurrentDialog = function (win) {
            var root = this.getRootWindow();
            if (root != window) return root.CMS.Dialog.getCurrentDialog(window);

            if (!win) win = window;

            for (var i = 0; i < dialogs.length; i++) {
                if (dialogs[i]) {
                    var cur = dialogs[i];
                    if (cur && cur.getWindow() == win) return cur;
                }
            }
            return false;
        }

        // возвращает номер текущего диалога в глобальном массиве диалогов
        this.currentDialogNum = function (win) {
            var root = getRootWindow();
            if (root != window) return root.CMS.Dialog.currentDialogNum(window);

            if (!win) win = window;

            for (var i = 0; i < dialogs.length; i++) {
                if (dialogs[i]) {
                    var cur = dialogs[i];
                    if (cur && cur.getWindow() == win) return i;
                }
            }

            return -1;
        }

        function getRootWindow() {
            var win = window;
            while (win.parent && win.parent != win && typeof win.parent.CMS !== 'undefined') {
                win = win.parent;
            }
            return win;
        }

        this.getParentDialog = function (win) {
            var root = getRootWindow();
            if (root != window) return root.CMS.Dialog.getParentDialog(window);

            if (!win) win = window;

            return dialogs[this.currentDialogNum(win) - 1];
        }

        function createWindowDialog(dialogurl, startwidth, startheight, onload, onclose) {
            var dialogframe;
            var $ = jQuery;

            if (!$(window).dialogWin) return false;

            document.body.style.overflow = 'hidden';

            dialogcount++;

            dialogframe = $('<iframe border="0" frameborder="0" style="width:100%;height:100%;"></iframe>').appendTo('body');

            dialogframe.dialogWin({
                closeBtn:true,
                refreshBtn:true,
                maxBtn:true,
                preloader:true,
                preloaderSrc:'/img/preloader2.gif',
                sizeable:true,
                onclose: function() {
                    dialog.onClose();
                    if(onclose) onclose();
                }
            }).hideWindow();

            dialogframe.ready(function() {
                dialogframe.showWindow();

                var frameDocument = dialogframe.contents();

                var caption = dialogframe.contents().find('title').text();
                if (caption.length > 40) caption = caption.substr(0, 37) + '...';

                var body = dialogframe.contents().find('body');
                body.addClass('cms-dialog');

                var width = frameDocument.width();
                var height = frameDocument.height();

                if (width < startwidth) width = startwidth;
                if (height < startheight) height = startheight;

                dialogframe
                    .setWindowSize(width, height, true)
                    .css('width', '100%')
                    .css('height', '100%')
                    .css('opacity', 1)
                    .hidePreloader()
                    .setCaption(caption);

                if(onload) {
                    onload(dialogframe);
                }
            });

            dialogframe.attr('src', dialogurl);

            dialogs.push(dialogframe);

            return true;
        }
    };
};

function showfoto(x, y, url) {
    return CMS.showPhoto(x, y, url);
}

function aliastranslit(str) {
    var trans = {
        'а':'a',
        'б':'b',
        'в':'v',
        'г':'g',
        'д':'d',
        'е':'e',
        'ё':'e',
        'ж':'zh',
        'з':'z',
        'и':'i',
        'й':'y',
        'к':'k',
        'л':'l',
        'м':'m',
        'н':'n',
        'о':'o',
        'п':'p',
        'р':'r',
        'с':'s',
        'т':'t',
        'у':'u',
        'ф':'f',
        'х':'h',
        'ц':'c',
        'ч':'ch',
        'ш':'sh',
        'щ':'csh',
        'ъ':'',
        'ы':'i',
        'ь':'',
        'э':'e',
        'ю':'u',
        'я':'ya',
        ' ':'-',
        '-':'-',
        'a':'a',
        'b':'b',
        'c':'c',
        'd':'d',
        'e':'e',
        'f':'f',
        'g':'g',
        'h':'h',
        'i':'i',
        'j':'j',
        'k':'k',
        'l':'l',
        'm':'m',
        'n':'n',
        'o':'o',
        'p':'p',
        'q':'q',
        'r':'r',
        's':'s',
        't':'t',
        'u':'u',
        'v':'v',
        'w':'w',
        'x':'x',
        'y':'y',
        'z':'z'
    };

    var res = '';

    for(i=0;i<str.length;i++) {
        var c = str[i];
        c = c.toLowerCase();
        if(trans[c]!=undefined ) {
            res += trans[c];
        } else
        if(c && parseInt(c)==c) {
            res += c;
        }
    }

    return res;
}

// plugin playlast
(function($) {
    var queuestorage = {};
    $.playLast = function(fn, delay, queue) {
        if(!queue) queue = 'default';

        if(queuestorage[queue]) clearTimeout(queuestorage[queue]);
        queuestorage[queue] = setTimeout(fn, delay);
    }
})(jQuery);

(function($) {
    var scripts = [];

    function loadScript(url, callback, context) {
        var script = scripts[url] || (scripts[url] = {
            loaded    : false,
            callbacks : []
        });

        if(script.loaded) {
            return callback.apply(context);
        }

        script.callbacks.push({
            fn      : callback,
            context : context
        });

        if(script.callbacks.length == 1) {
            $.ajax({
                type     : 'GET',
                url      : url,
                dataType : 'script',
                cache    : true,
                success  : function() {
                    script.loaded = true;
                    $.each(script.callbacks, function() {
                        this.fn.apply(this.context);
                    });
                    script.callbacks.length = 0;
                }
            });
        }
    }

    $.requireScript = function(url, callback, context, options) {
        if(typeof options === 'undefined' && context && context.hasOwnProperty && context.hasOwnProperty('parallel')) {
            options = context;
            context = window;
        }

        options = $.extend({ parallel : false }, options);

        if(!$.isArray(url)) {
            return loadScript(url, callback, context);
        }

        var counter = 0;

        // parallel loading
        if(options.parallel) {
            return $.each(url, function() {
                loadScript(this, function() {
                    if(++counter == url.length) {
                        callback.apply(context);
                    }
                });
            });
        }

        // sequential loading
        (function() {
            if(counter == url.length) {
                return callback.apply(context);
            }
            loadScript(url[counter++], arguments.callee);
        })();

    };

    $.requireScript.registerLoaded = function(url) {
        $.each($.makeArray(url), function() {
            (scripts[url] || (scripts[url] = {})).loaded = true;
        });
    };

    /*$.requireScript.registered = function() {
     return scripts;
     };*/

})(jQuery);

// plugin disableSelection
(function($) {
    if(!$.fn.disableSelection) {
        $.fn.disableSelection = function() {
            return this
                .attr('unselectable', 'on')
                .css('user-select', 'none')
                .on('selectstart', false);
        }
    }
})(jQuery);

// plugin touchHandler
(function($) {
    if(!document.addEventListener || !document.createEvent) return;

    var clickms = 200;
    var lastTouchDown = -1;

    function touchHandler(event) {
        var touches = event.changedTouches,
            first = touches[0],
            type = "";

        var d = new Date();
        switch(event.type)	{
            case "touchstart": type = "mousedown"; lastTouchDown = d.getTime(); break;
            case "touchmove": type="mousemove"; lastTouchDown = -1; break;
            case "touchend": if(lastTouchDown > -1 && (d.getTime() - lastTouchDown) < clickms){lastTouchDown = -1; type="click"; break;} type="mouseup"; break;
            default: return;
        }
        var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent(type, true, true, window, 1,
            first.screenX, first.screenY,
            first.clientX, first.clientY, false,
            false, false, false, 0, null);

        first.target.dispatchEvent(simulatedEvent);
        event.preventDefault();
    }

    function init()	{
        document.addEventListener("touchstart", touchHandler, true);
        document.addEventListener("touchmove", touchHandler, true);
        document.addEventListener("touchend", touchHandler, true);
        document.addEventListener("touchcancel", touchHandler, true);
    }

    //$(init);
})(jQuery);

(function($) {
    $.alignHeight = function(elements) {
        var maxh = 0;

        var els = [];
        if(elements instanceof jQuery) {
            elements.each(function() {
                els.push($(this));
            });
            elements = els;
        }

        for(var i in elements) {
            var element = elements[i];
            if(!(element instanceof jQuery) && typeof element == 'object') {
                var e = $(element[0]);
            } else {
                var e = $(element);
            }

            if(maxh<e.height()) maxh = e.outerHeight(true);
        }

        for(var i in elements) {
            var element = elements[i];
            if(!(element instanceof jQuery) && typeof element == 'object') {
                var e = $(element[0]);
                var delta = maxh-e.outerHeight(true);

                var sumh = 0;
                for(var j in element) {
                    if(j==0) continue;
                    sumh += $(element[j]).height();
                }

                if(sumh) {
                    for(var j in element) {
                        if(j==0) continue;
                        var e = $(element[j]);
                        var h = e.height();
                        var p = h/sumh;
                        e.css('height', (h+delta*p)+'px');
                    }
                } else {
                    var e = $(element[j]);
                    e.css('height', (delta)+'px');
                }
            } else {
                var e = $(element);
                var pad = e.outerHeight() - e.height();
                e.css('height', (maxh-pad)+'px');
            }
        }
    }

    $.fn.splitToColumn = function() {
        var columns = this;
        var items = columns.first().children();

        var count = items.length;
        var colcount = Math.ceil(count/columns.length);

        for(var i=1;i<columns.length;i++) {
            var column = columns.eq(i);
            for(var j=0;j<colcount;j++) {
                var el = items.eq(colcount*i+j);
                column.append(el);
            }
        }
    }

    $.fn.myDialog = function() {
        var widget = this.data('myDialog');
        return widget ? widget : {};
    }

    $.myDialog = function(initHtml) {
        var widget = {
            close: function() {
                if($.detectCSSFeature('transition')) {
                    window.on('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function() {
                        dialog.remove();
                        document.body.style.overflow = '';
                    })

                    window.addClass('animation-close');
                } else {
                    dialog.remove();
                    document.body.style.overflow = '';
                }
                return widget;
            },
            html: function(html) {
                if(typeof jQuery != 'undefined' && html instanceof jQuery) {
                    content.empty()[0].innerHTML = html.html();
                    content.trigger('init');
                } else {
                    content.empty()[0].innerHTML = $('<div>'+html+'</div>').html();
                    content.trigger('init');
                }
                return widget;
            },
            replace: function(element) {
                content.empty();
                widget.append(element);
                element.trigger('init');
                return widget;
            },
            append: function(element) {
                element.find('script').remove();
                content.append(element);
                element.trigger('init');
                return widget;
            },
            empty: function() {
                content.empty();
                return widget;
            },
            width: function(width) {
                if(!width) {
                    content.css('display', 'inline-block');
                    width = content.outerWidth();
                    content.css('display', '');
                }

                window.css('width', width);

                return widget;
            }
        }

        var dialog = $('<div class="myDialog">');
        var hider = $('<div class="myDialog-hider">').appendTo(dialog);
        var wrapper = $('<div class="myDialog-wrapper">').appendTo(dialog);
        var window = $('<div class="myDialog-window">').appendTo(wrapper);
        var close = $('<div class="myDialog-close" />').appendTo(window);
        var content = $('<div class="myDialog-content" />').appendTo(window);

        dialog.data('myDialog', widget);
        widget.$ = content;

        var closefn = function(event) {
            if(event.target==this) {
                widget.close();
            }
        };

        wrapper.click(closefn);
        hider.click(closefn);
        close.click(closefn);

        if(initHtml) {
            content.append(initHtml);
        } else {
            content.append('<div class="myDialog-loader" />');
        }

        document.body.style.overflow = 'hidden';
        $(document.body).append(dialog);

        return widget;
    }

    $.fn.ajaxLink = function(selector) {
        function ajaxLink(link) {
            var href = link.attr('href');
            if(!href) return;

            var hiderid = CMS.showHider();

            $.ajax(href, {
                dataType: 'html',
                success: function(html) {
                    var target = link.data('target');
                    if(target) {
                        if(typeof target == 'string') {
                            var content = $(target, '<div>'+html+'</div>');
                            if(content.length) {
                                $(target).empty()[0].innerHTML = content.html();
                                $(target).trigger('init');
                            } else {
                                $(target).empty()[0].innerHTML = html;
                                $(target).trigger('init');
                            }
                        } else {
                            target.empty()[0].innerHTML = html;
                            target.trigger('init');
                        }
                    } else {
                        var body = $('body', '<div>'+html+'</div>');
                        if(body.length) {
                            $('body').empty()[0].innerHTML = body.html();
                            $('body').trigger('init');
                        } else {
                            var first = $('div[id]:first', '<div>'+html+'</div>');
                            if(first.length) {
                                target = $('#'+first.attr('id'));
                                if(target.length) {
                                    target.empty()[0].innerHTML = first.html();
                                    target.trigger('init');
                                }
                            }
                        }
                    }

                    CMS.runScripts(html);

                    CMS.hideHider(hiderid);
                }
            });
        }

        if(selector) {
            $(this).on('click', selector, function(event) {
                event.preventDefault();
                ajaxLink($(this));
            });
        } else {
            this.click(function(event) {
                event.preventDefault();
                ajaxLink($(this));
            });
        }
    }

    $.fn.placeholder = function() {
        this.each(function() {
            var element = $(this);

            if(element[0].tagName.toLowerCase()=='input' && (element.attr('type')=='checkbox' || element.attr('type')=='radio' || element.attr('type')=='password')) return;
            if(!element.is(':visible')) return;

            var placeholder = element.attr('placeholder');
            if(!placeholder) return;
            if(element.data('placeholder')) return;
            element.data('placeholder', true);
            element.attr('placeholder', '');
            var label = $('<span />').html(placeholder).addClass('placeholder').css({'display':'block', 'position':'absolute', 'top':element.position().top, 'left':element.position().left, 'color':element.css('color'), 'paddingLeft':element.css('paddingLeft'), 'lineHeight':(element[0].tagName.toLowerCase() == 'input' ? element.outerHeight()+'px' : ''), 'paddingTop':(element[0].tagName.toLowerCase() == 'textarea' ? element.css('paddingTop') : element.css('marginTop')), 'whiteSpace':'nowrap', 'fontSize':element.css('fontSize')});
            label.click(function() {
                element.focus();
                element.trigger('click');
            });

            if(!element.val()) {
                label.show();
            } else {
                label.hide();
            }

            element.focusin(function() {
                label.hide();
            });
            element.focusout(function() {
                if(!element.val()) {
                    label.show();
                } else {
                    label.hide();
                }
            });
            element.change(function() {
                if(!$(this).val()) {
                    label.show();
                } else {
                    label.hide();
                }
            });
            $(window).resize(function() {
                label.css({maxWidth:element.width(), 'top':element.position().top, 'left':element.position().left, 'color':element.css('color'), 'paddingLeft':element.css('paddingLeft'), 'lineHeight':(element[0].tagName.toLowerCase() == 'input' ? element.outerHeight()+'px' : ''), 'paddingTop':(element[0].tagName.toLowerCase() == 'textarea' ? element.css('paddingTop') : element.css('marginTop')), 'whiteSpace':'nowrap', 'fontSize':element.css('fontSize')});
            });

            element.after(label);
        });
    }

    $.fn.admincontext = function(option) {
        var settings = {};

        $.extend(settings, option);

        return this.each(function() {
            var $el = $(this);
            var json = $el.attr('data-context');
            var data = JSON && JSON.parse(json) || $.parseJSON(json);

            $el.bind('mouseover', function(e) {
                e.stopPropagation();
                $this = $(this);
                $this.css('outline', '1px solid red');
            });
            $el.bind('mouseout', function(e) {
                e.stopPropagation();
                $this = $(this);
                $this.css('outline', 'none');
            });
            $el.bind('contextmenu rightclick', function(e) {
                e.preventDefault();
                $this = $(this);

                if(data.id) {
                    CMS.Dialog.create('/admin/'+data.modelName.toLowerCase()+'/update?id='+data.id, 960, 500);
                } else {
                    CMS.Dialog.create('/admin/'+data.modelName.toLowerCase()+'/create', 960, 500);
                }

                return false;
            });
        });
    }

    $.fn.imghover = function(option) {
        var settings = {};

        $.extend(settings, option);

        return this.each(function() {
            var $el = $(this);
            $el.mouseover(function() {
                var src = $(this).attr('src');
                $(this).data('orignsrc', src);
                var m = src.split('.');
                var ext = m.pop();
                src = m.join('.')+'0.'+ext;
                $(this).attr('src', src);
            });
            $el.mouseout(function() {
                $(this).attr('src', $(this).data('orignsrc'));
            });
        });
    }

    $.fn.waitimgs = function(callback) {
        if(!callback) return false;
        var imgs = this.find('img[src][src!=""]');
        var loadcounter = 0;
        var waitcount = 0;
        imgs.each(function() {
            var img = new Image();
            img.src = this.src;
            if(!img.complete) {
                img = new Image();
                waitcount++;
                $(img).one('load error', function() {
                    loadcounter++;
                    if(loadcounter==waitcount) {
                        callback();
                    }
                });
                img.src = this.src;
            }
        });
        if(!waitcount) callback();
    }

    $.fn.rowHeight = $.fn.rowheight = function(selector) {
        var rows = {};
        var self = this;

        if(selector) {
            self.find(selector).css('height', '');
        } else {
            self.css('height', '');
        }

        this.each(function() {
            var $el = $(this);
            var top = $el.position().top;
            if(!rows[top]) {
                rows[top] = [];
            }
            rows[top].push($el);
        });

        if(rows) {
            var top, i;
            for(top in rows) {
                var max = 0;
                if(rows[top].length>1) {
                    for(i in rows[top]) {
                        var e = rows[top][i];
                        var h = e.outerHeight();
                        if(h>max) max = h;
                    }
                    for(i in rows[top]) {
                        var e = rows[top][i];
                        if(selector) {
                            var sel = e.find(selector);
                            sel.outerHeight(sel.height()+max-e.outerHeight());
                        } else {
                            e.outerHeight(max);
                        }
                    }
                }
            }
        }

        var timer;
        $(window).resize(function() {
            if(timer) clearTimeout(timer);
            timer = setTimeout(function() {
                self.rowheight(selector);
            }, 1000);
        });
    }

    $.fn.myUpload = function(success, showHider, hideHider) {
        var self = this;
        var scriptfiles = [
            '/bower_components/jquery-ui/ui/widget.min.js',
            '/bower_components/blueimp-file-upload/js/jquery.iframe-transport.min.js',
            '/bower_components/blueimp-file-upload/js/jquery.fileupload.min.js'
        ];

        function firstVal(data) {
            for(var key in data) {
                return data[key];
            }
        }

        $.requireScript(scriptfiles, function() {
            self.each(function() {
                var element = $(this);
                var multiple = element.attr('multiple') ? true : false;

                element.fileupload({
                    dataType: 'json',
                    formData: function (form) {
                        var data = form.serializeArray();
                        data.push({name:'_', value:'1'});
                        return data;
                    },
                    send: function() {
                        if(showHider) {
                            if(typeof showHider == 'string') {
                                showHider(element);
                            } else {
                                showHider.call(element);
                            }
                        } else {
                            CMS.showHider();
                        }
                    },
                    done: function(e, data) {
                        if(hideHider) {
                            if(typeof hideHider == 'string') {
                                hideHider(element);
                            } else {
                                hideHider.call(element);
                            }
                        } else {
                            CMS.hideHider();
                        }

                        var el = $(e.target);
                        if(data.result && !data.result.errors) {
                            if(success) {
                                if(typeof success == 'string') {
                                    success(el, firstVal(data.result));
                                } else {
                                    success.call(el, firstVal(data.result));
                                }
                            } else {
                                if(!multiple) el.nextAll('input[type=hidden][rel=upload]').remove();
                                var hidden = $('<input type="hidden" />').attr('rel', 'upload');
                                if(multiple) {
                                    var name = el.attr('name');
                                    if(name.indexOf('[]')==-1) {
                                        name += '[]';
                                    }
                                    hidden.attr('name', name);
                                } else {
                                    hidden.attr('name', el.attr('name'));
                                }
                                hidden.val(firstVal(data.result).file);
                                el.after(hidden);
                            }
                            el.trigger('myUpload.success', firstVal(data.result));
                        } else {
                            if(data && data.result && data.result.errors)
                                alert(firstVal(data.result.errors));
                        }
                    }
                });
            });
        }, window);
    }

    $.fn.isVisible = function(fn) {
        function findHidden(el) {
            var p;
            while(el) {
                p = el.parent();
                if(!p || p[0].tagName.toLowerCase()=='body') {
                    return false;
                }
                if(p.is(':visible')) {
                    return el;
                } else {
                    el = p;
                }
            }
            return false;
        }

        this.each(function() {
            var el = $(this);
            if(el.is(':visible')) {
                fn.call(el);
            } else {
                var hidden = findHidden(el);
                if(hidden) {
                    hidden.one('show', function() {
                        el.isVisible(fn)
                    });
                }
            }
        });
    }

    $.detectCSSFeature = function(featurename){
        var feature = false,
            domPrefixes = 'Webkit Moz ms O'.split(' '),
            elm = document.createElement('div'),
            featurenameCapital = null;

        featurename = featurename.toLowerCase();

        if( elm.style[featurename] !== undefined ) { feature = true; }

        if( feature === false ) {
            featurenameCapital = featurename.charAt(0).toUpperCase() + featurename.substr(1);
            for( var i = 0; i < domPrefixes.length; i++ ) {
                if( elm.style[domPrefixes[i] + featurenameCapital ] !== undefined ) {
                    feature = true;
                    break;
                }
            }
        }
        return feature;
    }
})(jQuery);